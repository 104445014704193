import fetch from 'unfetch';
import { loadUiModuleAssets, loadEnvConfig, loadAsset } from './utils/asset-loader';
import { getFluidImports } from './utils/fluid-imports';
import * as assets from './utils/asset-url-service';
import * as eventBus from './utils/event-bus';
import makeManifestData from './utils/manifest-data-constructor';
import { getUiModDetails } from './utils/ui-module-helper';
import { showOverrideFooter } from './utils/override-footer';
import { applyOverrideState } from './utils/override-state';
import handleSetAssetUrl from './utils/set-asset-url-handler';
import { getQueryParamValue, removeQueryParam } from './utils/location-helpers';
import './state-handlers/404';

const logIt = (args = [], type = 'log') => { console && console[type] && console[type](...args) }; // eslint-disable-line
const _warn = (...args) => logIt(args, 'warn');
const _error = (...args) => logIt(args, 'error');
const _log = (...args) => logIt(args, 'log');
const isOfType = (val, type) => (
	Object.prototype.toString.apply(val).split(' ')[1].slice(0, -1).toLowerCase() === type.toLowerCase()
);

const overrideState = {};
const overrideFooterState = { env: null, assets: {}, buildConfig: null };

const createAlterFunc = (key, subject = 'OVERRIDE') => ({
	'⚙️  SETTINGS ⚙️': '',
	get [`REMOVE_${subject}`]() {
		window.localStorage.removeItem(key);
		window.location.reload();
		return '...REMOVED!';
	}
});

const sidekickPromise = Promise.all([
	loadAsset('css', `${assets.tools.sidekick}/sidekick.css`),
	loadAsset('js', `${assets.tools.sidekick}/sidekick.js`)
]).then(() => {
	window.sidekick.init({
		addOpenSans: false,
		addSidekickCss: false,
		cleanCache: (/^\/select\/?$/i).test(window.location.pathname)
	});
});


handleSetAssetUrl();

const envConfig = getQueryParamValue('envConfig');
if (envConfig != null) {
	window.localStorage.setItem('env', envConfig);
	removeQueryParam('envConfig');
}

// time to decide how to bootstrap
let envName = window.localStorage.getItem('env');
const isProductionDomain = window.location.host.endsWith('.frontlineeducation.com');
const isMorpheus = process.env.IDP === 'morpheus';
const envNameFromProcess = process.env.ENV_NAME;

if (envName === null) {
    if (isProductionDomain && !isMorpheus) {
        envName = 'production';
    } else {
        envName = 'stage';
        if (window.location.host.startsWith('supersuitawsqa')) {
            envName = window.location.pathname.startsWith('/pd-') ? 'pg_qa' : 'fc_qa';
        } else if (window.location.host.startsWith('supersuitawsdev')) {
            envName = 'fc_dev';
        }
        window.localStorage.setItem('env', envName);
    }
} else if (isMorpheus && envNameFromProcess != null) {
    envName = envNameFromProcess;
    window.localStorage.setItem('env', envName);
}

if (envName === null && envNameFromProcess != null) {
    envName = envNameFromProcess;
    window.localStorage.setItem('env', envName);
}

// if we have an environment override
if (envName != null && envName !== 'production') {
	overrideFooterState.env = envName;
	overrideState.env = envName;
	const isUrlRe = /^(https?:|\/\/)/;
	const configFile = process.env.IDP !== 'morpheus' ? envName : `morpheus-${envName}`;
	const envConfigUrl = (isUrlRe.test(envName) ? envName : `${process.env.BASE_URL}/env-configs/${configFile}.json`);
	// go get the config file for the overridden environment
	// and apply it prior to bootstrapping
	fetch(envConfigUrl)
		.then(res => res.json())
		.then(envCfg => window.buildConfig = envCfg)
		.then(() => {
			_warn(`ENVIRONMENT CONFIG: ${envName}`);
			goBootstrap();
		})
		.catch(err => {
			if (err.message === 'Unexpected token < in JSON at position 0') {
				_error(`MISSING ENVIRONMENT CONFIG: "${envName}" `, createAlterFunc('env', 'ENVIRONMENT'));
			} else {
				_error('ENVIRONMENT CONFIG ERROR', err);
			}
		});
} else {
	// no environment override to apply, go ahead and bootstrap
	goBootstrap();
}

function goBootstrap() {
	// copy the buildConfig so we can know what has been overridden later
	window.buildConfigOrig = JSON.parse(JSON.stringify(window.buildConfig));

	const buildConfigOverrides = Object.keys(window.localStorage)
		.filter(k => k.startsWith('buildConfig.'));
	overrideFooterState.buildConfig = buildConfigOverrides.length;
	buildConfigOverrides.forEach(envKey => {
		const attrPath = envKey.substr(12).split('.');
		const attrValue = window.localStorage.getItem(envKey);
		overrideState[envKey] = attrValue;
		const overrideResult = attrPath.reduce((obj, key) => {
			if (obj == null) { return null; }
			if (isOfType(obj[key], 'object')) {
				return obj[key];
			} else if (obj[key] != null) {
				obj[key] = attrValue;
				return true;
			} else {
				return null;
			}
		}, window.buildConfig);
		if (overrideResult == null || isOfType(overrideResult, 'object')) {
			_error('INVALID OVERRIDE:', `${envKey} = "${attrValue}"`);
		} else {
			_warn(`${envKey.split('.').join(' 〉')} 〉"${attrValue}" `, createAlterFunc(envKey));
		}
	});
	window.buildConfigData = JSON.parse(JSON.stringify(window.buildConfig));

	const { moduleBasePath } = window.buildConfig;

	// define SuperSuit global
	window.ss = window.ss || {};
	window.ss.env = Object.assign({}, assets);
	window.ss.events = eventBus;

	// notify developer via console about overridden asset locations
	Object.keys(window.localStorage)
		.filter(k => k.startsWith('assets.'))
		.forEach(envKey => {
			const [assetType, assetKey] = envKey.substr(7).split('.');
			const assetValue = window.localStorage.getItem(envKey);
			_warn(`${assetType} 〉${assetKey} 〉"${assetValue}" `, createAlterFunc(envKey));
			overrideFooterState.assets[assetType] = overrideFooterState.assets[assetType] || 0;
			overrideFooterState.assets[assetType] += 1;
			overrideState[envKey] = assetValue;
		});

	showOverrideFooter(overrideFooterState);
	let focused = true;
	window.addEventListener('focus', () => { focused = true; applyOverrideState(overrideState); });
	window.addEventListener('blur', () => focused = false);
	window.addEventListener('contextmenu', () => (!focused && applyOverrideState(overrideState)));
	const killPreLoader = () => window.document.getElementById('pre-loader').style.display = 'none';
	window.ss.util = { killPreLoader, getFluidImports, loadEnvConfig };

	Promise.all([fetch(`${moduleBasePath}/manifest.json`), sidekickPromise])
		.then(([res]) => res.json())
		.then(manifestV1 => makeManifestData(manifestV1, assets.uiModules))
		.then(manifest => {
			window.ss.env.manifest = manifest;
			const mod = getUiModDetails();

			// important auto-redirects
			const loc = window.location;
			// default url should be /select/
			// When there is no url then we need this to go to the org switcher and load main.js
			if (mod.urlValue === '') { loc.pathname = '/select/'; return; }
			// path /ui-module should be /ui-module/
			if (process.env.IDP === 'morpheus' && loc.pathname.endsWith(`${mod.urlValue}`)) {
				loc.pathname += '/';
				return;
			}
			if ((loc.pathname === `/${mod.urlValue}`)
				&& (!loc.pathname.includes('select') && !loc.pathname.includes('auth_callback'))) {
				loc.pathname += '/'; return;
			}

			if (!mod.isValid) {
				_warn(`module [${mod.urlValue}] not found`);
			} else {
				_log(`hooking up module ${mod.bootstrapName}`);
			}
			return loadUiModuleAssets(mod);
		})
		.then(() => {
			eventBus.on('route-change', (to, from) => {
				const toMod = getUiModDetails(to);
				const fromMod = getUiModDetails(from);
				if (toMod.bootstrapName !== fromMod.bootstrapName) {
					eventBus.fire('ui-module-deactivate', fromMod);
					loadUiModuleAssets(toMod);
				}
			});
		})
		.catch((e) => {
			_error(`threw up somewhere in app.js`);
		});

	window.document.addEventListener('keypress', evt => {
		const { keyCode, which, ctrlKey, shiftKey } = evt;
		const isActionKey = (which === 5 || keyCode === 5);
		const hasModifiers = (ctrlKey && shiftKey);
		if (isActionKey && hasModifiers) { loadEnvConfig(); }
	}, { once: true });

	// fluid-components snooty hookup
	if (window.location.hostname === 'localhost') {
		window.sessionStorage.setItem('snooty', `${process.env.BASE_URL}/fluid-components/snooty.min.js`);
	}
}
